export const messages = {
    itemError: 'An error occurred while processing your request. Please try again later.',
    itemSuccess: 'Item successfully added to wishlist',
    itemRemoved: 'Item removed from wishlist',
    sdfDownloadInit: 'SDF download will start up shortly. Please wait...',
    sdfDownloadError: 'An error occurred while processing your request. Please try again later.',
    backButtonEmpty: 'No history state to go back to.',
    addToWishlist: 'Add to wishlist',
    noOffersWarning: 'Sorry, no matching records found',
    resetFiltersLabel: 'Clear all filters',
    showMore: 'Show More',
    showLess: 'Show Less',
    productAddToCartError: 'An error occurred while adding the product to the cart. Please try again later.',
    productAddToCartSuccess: 'Product successfully added to cart.',
    productAddToCartUnsufficientAmount: 'Specified quantity exceeds available stock amount.',
    productAddToCartLabelLoading: 'Adding ...',
    productAddToCartButtonLabel: 'Add to cart',
    productAddToCartResponsiveButtonLabel: 'Add to cart',
    FetchError: 'Error fetching Google Scholar data',
    NoResults: 'No results found',
} as const

export const general = {
    downloadStarted: 'Your download has started.',
    downloadStartedAuto: 'Your download has started.',
    logoutSuccess: 'You have been logged out.',
} as const

export const auth = {
    loginSuccess: 'Welcome back! Login successful.',
    loginTimeout: 'Login attempt took too long. Please try again.',
    loginError: 'Login attempt failed. Please try again.',
} as const
