// Styles
import '../scss/main.scss'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { unsubscribe } from './components/auth/initialStateLoader.js'
import { dynamicModuleLoader } from './components/entryPoints/entryPoints'

// More JS dependencies
import './components/navbar/commonControls.js'
import './libs/mixpanel/mixpanelAutobinder.js'
import './components/common/lazyLoading.js'
import './components/navbar/navToggle.js'

/**
 * Adds an event listener for the `DOMContentLoaded` event.
 * 
 * This function performs the following tasks:
 * 
 * 1. When the DOM content is fully loaded, it calls the `dynamicModuleLoader` function.
 * 2. The `dynamicModuleLoader` function is responsible for loading JavaScript files dynamically based on the specific requirements of the page.
 * 
 * The purpose of this code is to ensure that the necessary JavaScript modules are loaded and executed only after the initial HTML document has been completely loaded and parsed, allowing for page-specific functionality to be initialized appropriately.
 */
document.addEventListener('DOMContentLoaded', function () {
    dynamicModuleLoader();
});

/**
 * Sets up click event listeners for elements with the class `modal-image` to open a modal displaying the image.
 * 
 * This function performs the following tasks:
 * 
 * 1. Selects all elements in the document with the class `modal-image`.
 * 2. Iterates over each selected element and adds a click event listener.
 * 3. When an element is clicked, the default action (e.g., following a link) is prevented.
 * 4. Retrieves the `href` attribute (as `imageSrc`) and the `title` or `data-image-description` attribute (as `description`) from the clicked element.
 * 5. Opens a modal using the `Lightbox` function, displaying the image specified by `imageSrc` and the description.
 * 
 * The purpose of this code is to provide a way to open images in a modal with a description when the user clicks on elements with the class `modal-image`.
 */
var modalImageLinks = document.querySelectorAll('.modal-image');

modalImageLinks.forEach(function (link) {
    link.addEventListener('click', function (event) {
        event.preventDefault();

        // Get the href attribute as the image source and the title or data-image-description attribute as the description
        var imageSrc = link.getAttribute('href');
        var description = link.getAttribute('title') || link.getAttribute('data-image-description');

        // Open the modal with the selected image and description using the Lightbox function
        Lightbox({ imageSrc, description, controls: true });
    });
});

/**
 * Initializes Bootstrap tooltips for all elements with the attribute `data-bs-toggle='tooltip'`.
 * 
 * This code performs the following tasks:
 * 
 * 1. Selects all elements in the document that have the attribute `data-bs-toggle='tooltip'`.
 * 2. Iterates over each selected element and initializes a new Bootstrap Tooltip instance for it.
 * 
 * The purpose of this code is to enable Bootstrap tooltips for any relevant elements on the page.
 */
import { Tooltip } from 'bootstrap'
 
let tooltipelements = document.querySelectorAll("[data-bs-toggle='tooltip']");

tooltipelements.forEach((el) => {
    new Tooltip(el);
});

/**
 * Handles the setup and functionality for toggling modals on click events.
 */
import { handleModalToggleClicks } from './components/common/modal/modalUtility.js'

handleModalToggleClicks();



import { Lightbox } from './components/controls/lightbox.js';
