import { handleEntryPoints } from 'vanillajs/utils/helpers.js'

export const dynamicModuleLoader = () => {
    // Router for dynamic module loading
    handleEntryPoints([
        {
            els: '.ph',
            load: () => import('vanillajs/libs/phosphorIcons/phosphorIcons.js'),
        },
        {
            els: '.ph-fill',
            load: () => import('vanillajs/libs/phosphorIcons/phosphorIcons.js'),
        },
        {
            els: '.ph-bold',
            load: () => import('vanillajs/libs/phosphorIcons/phosphorIcons.js'),
        },
        {
            els: '#signed-out',
            load: () => import('vanillajs/components/auth/signOut.js'),
        },
        {
            els: '#LoginForm',
            load: () => import('vanillajs/components/auth/signIn.js'),
        },
        {
            els: '#signed-in',
            load: () => import('vanillajs/components/auth/signInAuto.js'),
        },
        {
            els: '#change-country-btn',
            load: () => import('vanillajs/components/changecountry/changeCountry.js'),
        },
        {
            els: '#react-root',
            load: () => import('../../../react/app'),
        },
        {
            els: '.accept-cookies-btn',
            load: () => import('vanillajs/components/common/infoWindow.js'),
        },
        {
            els: '#announcement-bar',
            load: () => import('vanillajs/components/common/infoWindow.js'),
        },
        {
            els: '.faq-container',
            load: () => import('vanillajs/components/pages/helpcenter/helpcenter'),
        },
        {
            els: '#landmarks-continents',
            load: () => import('vanillajs/components/common/worldMap.js'),
        },
        {
            els: '#loading-progress-container',
            load: () => import('vanillajs/components/controls/loadingProgressBar.js'),
        },
        {
            els: '#RegisterForm',
            load: () => import('vanillajs/components/auth/signUp.js'),
        },
        {
            els: '#sharecart',
            load: () => import('vanillajs/components/shoppingcart/shareCart.js'),
        },
        {
            els: '#forgot-password-modal',
            load: () => import('vanillajs/components/auth/forgotPassword.js'),
        },
        {
            els: '.splide',
            load: () => import('vanillajs/libs/splidejs/splidInit.js'),
        },
        {
            els: '#splide-cards',
            load: () => import('vanillajs/libs/splidejs/splideCards.js'),
        },
        {
            els: '#structure-search-page',
            load: () => import('vanillajs/components/pages/search/structureSearch.js'),
        },
        {
            els: '#ShoppingCartForm',
            load: () => import('vanillajs/components/pages/shoppingCart/shoppingCart.js'),
        },
        {
            els: '.tel-field-with-validation',
            load: () => import('vanillajs/components/common/phoneNumberValidator.js'),
        },
        {
            els: '#quote-request-form',
            load: () => import('vanillajs/components/pages/requestForQuote/requestForQuote.js'),
        },
    ])
}
