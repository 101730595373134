import { Toast } from "bootstrap";

// Initialize Bootstrap toasts
const toastElements = document.querySelectorAll('.toast');
const toasts = Array.from(toastElements).map((el) => new Toast(el));

/**
 * Adds click event listeners to elements with the '.toast-btn' class to trigger the associated Bootstrap toast.
 * @param {MouseEvent} e - The click event object.
 */
document.querySelectorAll('.toast-btn').forEach((el) => {
    el.addEventListener("click", function (e) {
        const toastId = e.target.getAttribute("data-bs-toast-id");
        const toast = toasts.find((t) => t._element === document.querySelector(toastId));

        if (toast) {
            toast.show();
        }
    });
});

/**
 * Creates and displays a Bootstrap toast message with customizable options.
 *
 * @param {Object} options - Options for configuring the toast.
 * @param {string} options.message - The message content of the toast.
 * @param {string} options.icon - The icon from phosphor icons library (e.g., 'ph-user-circle').
 * @param {string} [options.toastType='toast'] - The type of toast (e.g., 'bg-success', 'bg-warning').
 * @param {string} [options.position='bottom-end'] - The toast's position on the screen.
 * @param {number} [options.delay=5000] - The delay in milliseconds before the toast automatically disappears.
 */
export function createToast(options) {
    const {
        message,
        icon,
        toastType = 'bg-success',
        delay = 7000,
    } = options;

    const toastContainer = document.querySelector(".toast-container");
    if (!toastContainer) {
        console.error(`Toast container '${containerSelector}' not found.`);
        return;
    }

    const toast = document.createElement('div');

    toast.setAttribute('aria-atomic', 'true');
    toast.setAttribute('aria-live', 'assertive');
    toast.classList.add('toast', toastType, 'text-light', 'border-0', 'shadow', 'rounded-3', 'fade');
    toast.setAttribute('role', 'alert');

    toast.innerHTML = `<div class="toast-body d-flex align-items-center justify-content-between">
                            <div class="fs-6 d-flex align-items-center">
                                <i class="ph ${icon} fs-4 me-1"></i>
                                ${message}
                            </div>
                            <button type="button" class="btn-close btn-close-white rounded-circle" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>`;

    toastContainer.appendChild(toast);
    const bsToast = new Toast(toast, { delay });
    bsToast.show();

    toast.addEventListener('hidden.bs.toast', function () {
        toastContainer.removeChild(toast);
    })
}
