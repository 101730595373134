import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartState {
    cartKey: string;
}

const initialState: CartState = {
    cartKey: '',
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCartKey: (state, action: PayloadAction<string>) => {
            state.cartKey = action.payload;
        }
    }
});

export const { setCartKey } = cartSlice.actions;

export const selectCartKey = (state: { cart: CartState }) => state.cart.cartKey;

export default cartSlice.reducer;
