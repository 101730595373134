import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from './reducers/loginSlice';
import cartReducer from './reducers/cartSlice';
import filtersReducer from './reducers/filtersSlice'; 
import sortReducer from './reducers/sortSlice'; 
import pendingDownloadReducer from './reducers/pendingDownloadSlice';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Combine the reducers
const rootReducer = combineReducers({
  login: loginReducer,
  cart: cartReducer,
  filters: filtersReducer,
  sort: sortReducer,
  pendingDownload: pendingDownloadReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Create the persistor
export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>; // Add this line to export RootState type
