/**
 * Sets up the toggle functionality for a navigation bar button icon.
 * 
 * This function performs the following tasks:
 * 
 * 1. Selects the navigation bar toggle button and the icon element.
 * 2. Adds a click event listener to the toggle button.
 * 3. Toggles the "open" class on the icon element based on the button's collapsed state.
 */
function setupNavbarToggle() {
  const toggleButton = document.querySelector(".navbar-toggler");
  const toggleIcon = document.querySelector(".animated-icon1");

  if (!toggleButton || !toggleIcon) return;

  toggleButton.addEventListener('click', () => {
    toggleIcon.classList.toggle("open", !toggleButton.classList.contains("collapsed"));
  });
}

// Initialize the navbar toggle functionality when the DOM content is fully loaded
document.addEventListener("DOMContentLoaded", setupNavbarToggle);
