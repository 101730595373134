import store, { persistor } from '../../../store/store'
import { toggleClasses, isURL } from '../../utils/helpers.js'
import { createToast } from '../controls/toast.js'

const messages = {
    serverError: 'Sorry, an error occurred. Please try again later.',
}

/**
 * Update the navigation bar based on the user's login state.
 *
 * @param {Object} state - The application state containing login information.
 */
export const handleLoginStateChange = (state) => {
    const loggedUserNav = document.querySelectorAll('.loggedUserNav')
    const guestUserNav = document.querySelectorAll('.guestUserNav')

    const userElements = document.querySelectorAll('[data-user-component]')
    const isLoggedIn = state.login.isLoggedIn
    const user = state.login.user

    if (isLoggedIn) {
        //const username = state.login.user?.username
        const firstname = user?.firstname
        const formattedName = user?.username
            ? state.login.user.username.includes('@')
                ? state.login.user.username.substring(0, state.login.user.username.indexOf('@'))
                : state.login.user.username
            : 'Customer'

        toggleClasses(loggedUserNav, 'd-flex', 'd-none')
        toggleClasses(guestUserNav, 'd-none', 'd-flex')
        toggleClasses(userElements, 'd-none', 'd-flex')

        loggedUserNav.forEach((nav) => {
            nav.querySelector('.user-auth-info').innerHTML = `Hello, ${firstname ? firstname : formattedName}`
        })

        userElements.forEach((element) => {
            const isUserComponentTrue = element.getAttribute('data-user-component') === 'true'
            const isUserComponentFalse = element.getAttribute('data-user-component') === 'false'

            if (isUserComponentTrue) {
                element.classList.remove('d-none')
            } else if (isUserComponentFalse) {
                element.classList.add('d-none')
            }
        })

        adaptUserTypeView(user.usertype)
    } else {
        // Show guest user navigation
        toggleClasses(loggedUserNav, 'd-none', 'd-flex')
        toggleClasses(guestUserNav, 'd-block', 'd-none')

        userElements.forEach((element) => {
            const isUserComponentTrue = element.getAttribute('data-user-component') === 'true'
            const isUserComponentFalse = element.getAttribute('data-user-component') === 'false'

            if (isUserComponentTrue) {
                element.classList.add('d-none')
            } else if (isUserComponentFalse) {
                element.classList.remove('d-none')
            }
        })
    }
}

/**
 * Hides elements with specified user types by adding the 'd-none' class.
 * Elements can have multiple user types specified, separated by commas.
 *
 * @param {number} usertype - The user type to filter elements by, default is 0 (unauthorized users).
 */
function adaptUserTypeView(usertype = 0) {
    // Select all elements that might have a user type attribute
    const userroleComponents = document.querySelectorAll('[data-usertype-component-hidden]');

    // Filter and hide elements that include the specified usertype in their data attribute
    userroleComponents.forEach((element) => {
        const hiddenUserTypes = element.getAttribute('data-usertype-component-hidden').split(',').map(type => type.trim());
        if (hiddenUserTypes.includes(usertype.toString())) {
            element.classList.add('d-none');
        }
    });
}


/**
 * Remove Bootstrap-specific data attributes from elements in the document.
 * This function targets elements with data attributes starting with 'data-bs'
 * and removes those attributes.
 */
export const removeBSDataAttributesGlobally = (remove = false) => {
    const allElementsWithClasses = document.querySelectorAll('.user-login-modal, .user-register-modal')

    if (remove) {
        allElementsWithClasses.forEach((element) => {
            element.classList.remove('user-login-modal', 'user-register-modal')
        })
    }
}

/**
 * Follow a specified data attribute by either navigating to a URL or executing a JavaScript expression.
 *
 * @param {string} nextstep - The value of the data attribute to follow.
 * @param {string} message - The message for the toast notification (optional, default: false).
 */
export function followProceedDataAttr(nextstep, message = false) {
    if (message !== false) {
        createToast({ message: message, toastType: 'bg-light', icon: 'ph-download-simple' })
    }

    if (isURL(nextstep)) {
        // Navigate to the specified URL
        window.location.href = nextstep
    } else {
        // Execute the JavaScript expression
        try {
            eval(nextstep)
        } catch (error) {
            createToast({ message: messages.serverError, toastType: 'bg-danger', icon: 'ph-warning-circle' })
        }
    }
}

/**
 * Handle button actions based on user login state.
 *
 * @param {boolean} isLoggedIn - Indicates whether the user is logged in (optional, default: false).
 */
export const handleButtonHrefs = (isLoggedIn = false) => {
    const elements = document.querySelectorAll('[data-user-registered-action]')

    if (isLoggedIn) {

        // Hook up click events for buttons
        elements.forEach((element) => {
            element.addEventListener('click', () => {
                followProceedDataAttr(
                    element.getAttribute('data-user-registered-action'),
                    element.getAttribute('data-url-type') === 'download' ? 'Download has started' : false
                )
            })
        })
    }
}
