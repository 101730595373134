import { Tooltip } from 'bootstrap';

/**
 * Sets up the "back to top" button functionality.
 * 
 * This function performs the following tasks:
 * 
 * 1. Selects the "back to top" button element.
 * 2. Adds a scroll event listener to the window to show/hide the button based on scroll position.
 * 3. Adds a click event listener to the button to smoothly scroll to the top of the page.
 */
function setupBackToTopButton() {
  const backToTopBtn = document.getElementById("btn-back-to-top");
  if (!backToTopBtn) return;

  window.addEventListener("scroll", () => {
    backToTopBtn.classList.toggle("d-none", window.scrollY <= 90);
  });

  backToTopBtn.addEventListener("click", () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
}

/**
 * Sets up smooth scrolling for elements with the class "scrollto-element".
 * 
 * This function performs the following tasks:
 * 
 * 1. Selects all elements with the class "scrollto-element".
 * 2. Adds a click event listener to each element to smoothly scroll to the target element specified by the data attribute.
 */
function setupSmoothScroll() {
  const scrollToElements = document.querySelectorAll(".scrollto-element");
  scrollToElements.forEach((elem) => {
    elem.addEventListener("click", (event) => {
      event.preventDefault();
      const targetId = event.target.getAttribute("data-scrolltoelement");
      const yOffset = -80;
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    });
  });
}

/**
 * Initializes Bootstrap tooltips for elements with the attribute `data-bs-toggle="tooltip"`.
 * 
 * This function performs the following tasks:
 * 
 * 1. Selects all elements with the attribute `data-bs-toggle="tooltip"`.
 * 2. Iterates over each selected element and initializes a new Bootstrap Tooltip instance.
 */
function initializeTooltips() {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new Tooltip(tooltipTriggerEl);
  });
}

/**
 * Handles the submission of main search forms.
 * 
 * This function performs the following tasks:
 * 
 * 1. Selects all elements with the class "main__search-forms".
 * 2. Adds a submit event listener to each form to prevent the default form submission and then submits the form.
 */
function handleMainSearchForms() {
  const mainSearchForms = document.querySelectorAll(".main__search-forms");
  mainSearchForms.forEach((form) => {
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      form.submit();
    });
  });
}

/**
 * Sets up the sticky header functionality.
 * 
 * This function performs the following tasks:
 * 
 * 1. Selects the header element with the class "nav-wrapper".
 * 2. Adds a scroll event listener to the window to toggle the "sticky" class on the header based on scroll position.
 * 3. Adjusts the margin of the header and updates the styles of shopping cart dropdowns when the header becomes sticky.
 */
function setupStickyHeader() {
  const header = document.querySelector(".nav-wrapper");
  if (!header) return;

  const navPos = header.offsetTop;
  const shoppingCartDropdown = document.querySelector("#shopping-cart-mobile");
  const shoppingCartDropdownLg = document.querySelector("#shopping-cart-lg .shopping_cart");

  window.addEventListener("scroll", () => {
    const isSticky = window.scrollY > navPos;
    header.classList.toggle("sticky", isSticky);
    header.classList.toggle("navbarOffsetMargin", isSticky);
    header.style.marginTop = isSticky ? "0px" : "";

    if (shoppingCartDropdown && shoppingCartDropdownLg) {
      updateShoppingCartStyles(isSticky ? "10px" : "", isSticky ? "200px" : "");
    }
  });

  /**
   * Updates the styles of shopping cart dropdowns based on the provided values.
   * 
   * @param {string} rightValue - The right CSS value for the shopping cart dropdowns.
   * @param {string} marginTop - The margin-top CSS value for the shopping cart dropdowns.
   */
  function updateShoppingCartStyles(rightValue, marginTop) {
    const styles = `right: ${rightValue} !important; width: 310px; margin-top: ${marginTop}`;
    shoppingCartDropdown.style.cssText = styles;
    shoppingCartDropdownLg.style.cssText = styles;
  }
}

/**
 * Adds an event listener for the `DOMContentLoaded` event to initialize various functionalities.
 * 
 * This function performs the following tasks:
 * 
 * 1. Calls `setupBackToTopButton` to set up the "back to top" button functionality.
 * 2. Calls `setupSmoothScroll` to set up smooth scrolling for elements with the class "scrollto-element".
 * 3. Calls `initializeTooltips` to initialize Bootstrap tooltips.
 * 4. Calls `handleMainSearchForms` to handle the submission of main search forms.
 * 5. Calls `setupStickyHeader` to set up the sticky header functionality.
 */
document.addEventListener("DOMContentLoaded", () => {
  setupBackToTopButton();
  setupSmoothScroll();
  initializeTooltips();
  handleMainSearchForms();
  setupStickyHeader();
});
