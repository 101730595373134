const loadSingleEntryPoint = ({
    // Elements selectors
    els = [],

    // Execute something before the actual load of the module
    beforeLoad = (el) => {},

    // Perform module load & return a Promise
    load,

    // mount logic can be changed, if needed
    // mount = ({ mount, el }) => (el ? mount(el) : mount()),

    // Define a condition that if not satisfied, will prevent the module from
    // loading
    condition,
}) => {
    if (els && {}.toString.call(els) === '[object Function]') {
        els = els()
    }

    const allEls = (Array.isArray(els) ? els : [els]).reduce(
        (a, selector) => [...a, ...(Array.isArray(selector) ? selector : document.querySelectorAll(selector))],
        []
    )

    allEls.map(beforeLoad)

    if (allEls.length === 0) {
        return
    }

    if (
        condition &&
        !condition({
            els,
        })
    ) {
        return
    }

    load().then((arg) =>
        allEls.map((el) => {
            //mount({ ...arg, el })
        })
    )
}

export const handleEntryPoints = (mountEntryPoints = []) => {
    mountEntryPoints.map(loadSingleEntryPoint)
}

// Helper function to get the width of the document
export function getWidth() {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    )
}

// Helper function to toggle CSS classes
export const toggleClasses = (elements, addClass, removeClass) => {
    elements.forEach((element) => {
        element.classList.add(addClass)
        element.classList.remove(removeClass)
    })
}

// Helper function to check if a string is a URL
export function isURL(str) {
    const pattern = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return pattern.test(str)
}

export function scrollToElement(element, yOffset = -200) {
    if (!element) return // Check if the element exists

    const y = element.getBoundingClientRect().top + window.scrollY + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
}

/**
 * Checks if an element is an anchor (link).
 *
 * @param {HTMLElement} element - The HTML element to check.
 * @returns {boolean} - True if the element is an anchor, false otherwise.
 */
export function isAnchorElement(element) {
    return element.nodeName === 'A'
}

export const commonSearchHandler = (value, searchType) => {
    const form = document.createElement('form')

    form.action = `search-in-progress?searchtype=${searchType}&t=${Math.floor(Math.random() * 99999999)}`
    form.method = 'POST'

    const input = document.createElement('input')
    input.type = 'hidden'
    input.value = value
    input.name = 'searchvalue'

    form.appendChild(input)

    document.body.appendChild(form)

    form.submit()

    document.body.removeChild(form)
}
