import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FiltersState, Filter } from '@/types/global';

// Initial state with the correct type
const initialState: FiltersState = {
  filters: [],
  sort: [], // Initialize sort if it's part of the state
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterCriteria: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload;
    },
    addFilter: (state, action: PayloadAction<Filter>) => {
      state.filters.push(action.payload);
    },
    removeFilterItem: (state, action: PayloadAction<number>) => {
      state.filters.splice(action.payload, 1);
    },
    updateFilter: (state, action: PayloadAction<{ index: number; filter: Filter }>) => {
      const { index, filter } = action.payload;
      if (index >= 0 && index < state.filters.length) {
        state.filters[index] = filter; // Ensures the index is within bounds
      }
    },
    upsertFilter: (state, action: PayloadAction<Filter>) => {
      const { column, operation, value } = action.payload;
      const existingIndex = state.filters.findIndex(
        (filter) => filter.column === column && filter.operation === operation
      );

      if (existingIndex >= 0) {
        // Update existing filter
        state.filters[existingIndex].value = value;
      } else {
        // Add new filter
        state.filters.push({ column, operation, value });
      }
    },
    addMultipleFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters.push(...action.payload);
    },
    removeAllFilters: (state) => {
      state.filters = [];
    },
    resetAll: (state) => {
      state.filters = [];
      state.sort = [];
    },
  },
});

export const {
  setFilterCriteria,
  addFilter,
  removeFilterItem,
  updateFilter,
  upsertFilter,
  addMultipleFilters,
  removeAllFilters,
  resetAll,
} = filtersSlice.actions;

export default filtersSlice.reducer;
