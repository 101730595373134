import store from '../../../store/store'
import { handleLoginStateChange, removeBSDataAttributesGlobally, handleButtonHrefs } from 'vanillajs/components/navbar/userAuthStatus.js'
import { createToast } from 'vanillajs/components/controls/toast.js'
import { general } from '../../../react/messages/messages'
import { loadModal } from '../common/modal/loadModal.js'

/**
 * Subscribes to changes in the Redux store and performs relevant actions based on the application state.
 */
const unsubscribe = store.subscribe(() => {
    // Get the updated state whenever it changes
    const state = store.getState()

    // Handle changes related to login state and buttons
    handleLoginStateChange(state)

    // Check if the login modal element exists in the DOM
    if (!state.login.isLoggedIn) {
        removeBSDataAttributesGlobally(false)
    }

    // If the user is logged in, remove Bootstrap data attributes from the document
    if (state.login.isLoggedIn) {
        handleButtonHrefs(state.login.isLoggedIn)
        executePostLoginAction()

        // Subscribe to changes in the isLoggedIn state
        const unsubscribe2 = store.subscribe(() => {
            const state = store.getState()
            if (!state.login.isLoggedIn) {
                // The isLoggedIn state changed to false
                createToast({ message: general.logoutSuccess, toastType: 'bg-success', icon: 'ph-user-circle', delay: 2000 })
                unsubscribe2() // Unsubscribe when the user logs out
            }
        })
    }
})

// Helper function to check if user is logged in
export const checkIsLoggedIn = () => {
    const state = store.getState() // Use a direct call to get the specific state slice
    return state.login.isLoggedIn;
};

// Extracts the action from sessionStorage and decides the execution path
export const executePostLoginAction = () => {
    const action = sessionStorage.getItem('postLoginAction');
    if (!action) {
        //console.log('No action to execute.');
        return;
    }

    //console.log('Executing post-login action:', action);

    if (!handleAction(action)) {
        console.error('No valid action found or action format is incorrect:', action);
    }

    sessionStorage.removeItem('postLoginAction'); // Clean up after executing
};

// Determines the type of action and delegates to the appropriate handler
function handleAction(action) {
    const propertyMatch = action.match(/javascript:\s*([\w\.]+)\s*=\s*['"]([^'"]+)['"];?/);
    const functionMatch = action.match(/javascript:\s*(\w+)\(([^)]*)\);?/);

    if (propertyMatch) {
        const [_, propertyPath, value] = propertyMatch;
        return handlePropertyAssignment(propertyPath, value);
    } else if (functionMatch) {
        const [_, functionName, paramString] = functionMatch;
        return handleFunctionCall(functionName, paramString);
    }
    return false;
}
// Handles property assignments safely without eval
function handlePropertyAssignment(propertyPath, value) {
    const pathParts = propertyPath.split('.');
    let target = window;
    for (let i = 0; i < pathParts.length - 1; i++) {
        target = target[pathParts[i]];
        if (typeof target !== 'object' || target === null) {
            console.error('Invalid property path:', propertyPath);
            return false;
        }
    }
    const propertyName = pathParts.pop();
    target[propertyName] = value;
    return true;
}

// Handles function calls safely without eval
function handleFunctionCall(functionName, paramString) {
    const args = paramString.split(',').map(arg => arg.trim().replace(/^['"]|['"]$/g, ''));
    const func = window[functionName];
    if (typeof func === 'function') {
        func(...args);
        return true;
    } else {
        console.error('Function not found:', functionName);
        return false;
    }
}

// Attaches click event listeners to elements requiring user registration actions
const attachActionListeners = () => {
    document.querySelectorAll('[data-user-registered-action]').forEach(element => {
        element.addEventListener('click', (e) => {
            
            if (!checkIsLoggedIn()) {
                e.preventDefault();
                const action = element.getAttribute('data-user-registered-action');
                if (action) {
                    sessionStorage.setItem('postLoginAction', action);
                }
                if(e.target.getAttribute('data-show-modal')) {
                    loadModal(e.target.getAttribute('data-show-modal')); 
                } else {
                    loadModal('Login'); // Assumed function to show login modal
                }
            }
        });
    });
};

// Initialize the event listeners
attachActionListeners();