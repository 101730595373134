import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SortCriterion {
    column: string;
    order: 'ascend' | 'descend';
}

interface SortState {
    sortCriteria: SortCriterion[];
}

const initialState: SortState = {
    sortCriteria: [],
};

export const sortSlice = createSlice({
    name: 'sort',
    initialState,
    reducers: {
        setSortCriteria: (state, action: PayloadAction<SortCriterion[]>) => {
            state.sortCriteria = action.payload; // Expecting an array of sort criteria
        },
        addSortCriterion: (state, action: PayloadAction<SortCriterion>) => {
            state.sortCriteria.push(action.payload); // Expecting a sort criterion
        },
        removeSortCriterion: (state, action: PayloadAction<number>) => {
            state.sortCriteria.splice(action.payload, 1); // Expecting an index
        },
        updateSortCriteria: (state, action: PayloadAction<SortCriterion[]>) => {
            state.sortCriteria = action.payload; // Expecting an array of sort criteria
        },
        resetSort: (state) => {
            state.sortCriteria = [];
        },
    },
});

export const { setSortCriteria, addSortCriterion, removeSortCriterion, updateSortCriteria, resetSort } = sortSlice.actions;

export default sortSlice.reducer;
