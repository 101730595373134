import { Modal } from 'bootstrap'
import { loadModal } from './loadModal'

/**
 * Opens a modal identified by the provided selector. The function initializes
 * or retrieves an instance of Bootstrap's Modal component for the target modal
 * element. If an `onShown` callback is provided, it will be executed once the
 * modal is fully shown. This function assumes Bootstrap 5's Modal class is used.
 *
 * @param {string} selector The CSS selector for the modal element.
 * @param {Function|null} [onShown] Optional callback function to execute when the modal is fully shown.
 */

export function openModal(selector, onShownCallback = null) {
    // Ensure the Modal class is available
    // if (typeof bootstrap === 'undefined' || !bootstrap.Modal) {
    //     console.error('Bootstrap\'s JavaScript is required but was not found.');
    //     return;
    // }

    const modalElement = document.querySelector(selector)
    if (!modalElement) {
        console.warn(`No modal found with selector: ${selector}`)
        return
    }

    const modalInstance = new Modal(modalElement, {
        backdrop: 'static', // Use 'static' for a backdrop which doesn't close the modal on click.
        keyboard: false, // Set to false if you want the modal not to close when the escape button is pressed.
    })

    // Setup event listener for when the modal is fully shown
    if (onShownCallback && typeof onShownCallback === 'function') {
        modalElement.addEventListener('shown.bs.modal', onShownCallback, { once: true })
    }

    modalInstance.show()
}

/**
 * Closes the modal(s) based on the provided selector(s).
 * Accepts multiple selectors as arguments for flexibility.
 * @param {...String} selectors - The CSS selectors of the modals to close.
 */
export const closeModal = (...selectors) => {
    selectors.forEach((selector) => {
        document.querySelectorAll(selector).forEach((modalElement) => {
            // Check if the modal instance exists and then hide it.
            // This is a generic approach; adapt it based on the modal library you are using.
            const modalInstance = Modal.getInstance(modalElement) // Bootstrap example
            if (modalInstance) {
                modalInstance.hide()
            }
        })
    })
}

let dataElementNameVar = ''

export const handleModalToggleClicks = () => {
    document.body.addEventListener('click', (event) => {
        const modalActionMap = {
            'user-login-modal': 'Login',
            'user-register-modal': 'Register',
            'user-forgotpassword-modal': 'ForgotPassword',
            'switch-to-register': 'Register',
            'switch-to-login': 'Login',
            'shoppingcart-sharecart-modal': 'ShareCart',
            'shoppingcart-sharecart-success': 'shareCartSuccess',
            'switch-to-forgotpassword': 'ForgotPassword',
            'change-country-modal': 'ChangeCountry',
        }

        // Find the target class based on the clicked element
        const targetClass = Object.keys(modalActionMap).find(
            (className) => event.target.closest(`.${className}`) // Use .closest to account for nested elements within the clickable area
        )

        if (targetClass) {
            const action = modalActionMap[targetClass]

            if (event.target.closest('.user-login-modal') || event.target.closest('.user-register-modal')) {
                const dataElementName = event.target.getAttribute('data-element-name')
                dataElementNameVar = dataElementName
            }

            if (['switch-to-register', 'switch-to-login', 'switch-to-forgotpassword'].includes(targetClass)) {
                // Close all potential modals before opening a new one
                closeModal('#login', '#register', '#forgotpassword', '#forgotpasswordsuccess') // Ensure you are targeting the correct modals
            }

            loadModal(action)
        }
    })

    // Automatically open the login modal if the auto-open class is present
    document.addEventListener('DOMContentLoaded', () => {
        const autoOpenElement = document.querySelector('.user-login-modal-autoopen')

        if (autoOpenElement) {
            loadModal('Login', '', true)
        }
    })

    const observer = new MutationObserver((mutationsList, observer) => {
        const hiddenInput = document.getElementById('MixpanelElement')
        if (hiddenInput && dataElementNameVar) {
            hiddenInput.value = dataElementNameVar
        }
    })

    observer.observe(document.body, { childList: true, subtree: true })
}
