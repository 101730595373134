import { createToast } from '../controls/toast';
import { Modal } from 'bootstrap';

export function Lightbox(options) {
    const {
        imageSrc,
        controls = false,
    } = options;

    // Create a modal element
    const modal = document.createElement('div');
    modal.classList.add('modal', 'fade');

    // Create a modal dialog element
    const modalDialog = document.createElement('div');
    modalDialog.classList.add('modal-dialog', 'modal-dialog-centered', 'modal-lg', 'modal-lightbox');

    // Create a modal content element
    const modalContent = document.createElement('div');
    modalContent.classList.add('modal-content');

    // Create a modal header with a close button
    const modalHeader = document.createElement('div');
    modalHeader.classList.add('modal-header');

    // Add description to modal header
    const descriptionElement = document.createElement('span');
    descriptionElement.classList.add('text-start', 'lead');
    descriptionElement.textContent = options.description || '';

    modalHeader.appendChild(descriptionElement);

    // Create a "Copy to Clipboard" button
    if (controls === true && window.isSecureContext) {
        const copyButton = document.createElement('button');
        copyButton.innerHTML = `<i class="ph ph-copy"></i> Copy`;
        copyButton.classList.add('btn', 'btn-sm', 'bg-light', 'no-underline', 'ms-3', 'd-block');
        copyButton.addEventListener('click', function () {
            // Copy the description text to the clipboard
            const textarea = document.createElement('textarea');
            textarea.value = descriptionElement.textContent;
            document.body.appendChild(textarea);
            textarea.select();
            textarea.setSelectionRange(0, 99999); // For mobile devices
            navigator.clipboard.writeText(textarea.value);

            document.body.removeChild(textarea);

            // Change button text to indicate success
            copyButton.innerHTML = `<i class="ph ph-check"></i> Copied`;
            createToast({ message: 'Copied to clipboard', toastType: 'bg-success', icon: 'ph-check', delay: 1500 });
            setTimeout(function () {
                copyButton.innerHTML = `<i class="ph ph-copy"></i> Copy`;
            }, 1500);
        });

        modalHeader.appendChild(copyButton);
    }

    const closeButton = document.createElement('button');
    closeButton.type = 'button';
    closeButton.classList.add('bg-light', 'rounded-pill', 'btn-close');
    closeButton.setAttribute('data-bs-dismiss', 'modal');
    closeButton.setAttribute('aria-label', 'Close');

    modalHeader.appendChild(closeButton);

    // Create a modal body element with the image
    const modalBody = document.createElement('div');
    modalBody.classList.add('modal-body', 'p-0', 'm-0');

    const imageElement = document.createElement('img');
    imageElement.src = imageSrc;
    imageElement.classList.add('img-fluid', 'w-100');
    imageElement.alt = 'molecule image';

    modalBody.appendChild(imageElement);

    // Append elements to build the modal structure
    modalContent.appendChild(modalHeader);
    modalContent.appendChild(modalBody);
    modalDialog.appendChild(modalContent);
    modal.appendChild(modalDialog);

    // Append the modal to the document's body
    document.body.appendChild(modal);

    // Initialize the Bootstrap modal and show it
    const modalInstance = new Modal(modal);
    modalInstance.show();
}
