import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PendingDownloadState {
    isPending: boolean;
}

const initialState: PendingDownloadState = {
    isPending: false,
};

const pendingDownloadSlice = createSlice({
    name: 'pendingDownload',
    initialState,
    reducers: {
        setPendingDownload: (state, action: PayloadAction<boolean>) => {
            state.isPending = action.payload;
        },
    },
});

export const { setPendingDownload } = pendingDownloadSlice.actions;

export default pendingDownloadSlice.reducer;
