document.addEventListener("DOMContentLoaded", function() {
  /**
   * Function to initialize lazy loading for images.
   * 
   * This function sets up an IntersectionObserver to observe elements with the "lazy" class.
   * When these elements come into view, their `src` and `srcset` attributes are updated to load the actual image,
   * and the "lazy" class is replaced with "animate__fade-in" for a fade-in effect. It also removes the 
   * "placeholder-wave" class from the closest parent element with that class.
   */
  function initializeLazyLoading() {
      var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    
      if ("IntersectionObserver" in window) {
          let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
              entries.forEach(function(entry) {
                  if (entry.isIntersecting) {
                      let lazyImage = entry.target;
                      lazyImage.src = lazyImage.dataset.src;
                      lazyImage.srcset = lazyImage.dataset.srcset;

                      lazyImage.classList.remove("lazy");
                      lazyImage.classList.add("animate__fade-in");

                      let parentElement = lazyImage.closest(".placeholder-wave");
                      if (parentElement) {
                          parentElement.classList.remove("placeholder-wave");
                      }
                      
                      lazyImageObserver.unobserve(lazyImage);
                  }
              });
          });
      
          lazyImages.forEach(function(lazyImage) {
              lazyImageObserver.observe(lazyImage);
          });
      } else {
          // Fallback to event handlers if IntersectionObserver is not supported
      }
  }

  // Initialize lazy loading when the DOM content is fully loaded
  initializeLazyLoading();
});
