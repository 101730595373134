import { Modal } from 'bootstrap'

// Example function to dynamically create and show a modal
export function createModal(id, title, bodyContent, onShown = null, options = {}) {
    const { size = '', backdrop = true, keyboard = true } = options
    const modalHTML = `
        <div class="modal fade" tabindex="-1" id="${id}">
        <div class="modal-dialog modal-dialog-centered ${size ? `modal-${size}` : ''}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-medium">${title}</h5>
                        <button type="button" class="btn-close rounded-circle" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body px-3 px-lg-5 py-2 py-lg-3">
                        ${bodyContent}
                    </div>
                </div>
            </div>
        </div>
    `

    document.body.insertAdjacentHTML('beforeend', modalHTML)
    const modalElement = document.getElementById(id)
    const modalInstance = new Modal(modalElement, {
        backdrop: backdrop,
        keyboard: keyboard,
    })
    modalInstance.show()

    if (onShown && typeof onShown === 'function') {
        modalElement.addEventListener('shown.bs.modal', onShown, { once: true })
    }

    // Optional: Remove the modal from the DOM when it's closed
    modalElement.addEventListener('hidden.bs.modal', () => {
        modalElement.remove()
    })
}
