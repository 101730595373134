import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, LoginState } from '@/types/global';

const initialState: LoginState = {
  isLoggedIn: false,
  user: null,
  usertype: null,
  libraryCovalentsAccess: null,
  compoundPageVersion: 2,
  error: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.usertype = action.payload.usertype;
      state.compoundPageVersion = action.payload.compoundPageVersion ?? 2;
      state.libraryCovalentsAccess = action.payload.libraryCovalentsAccess;
      state.error = null;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isLoggedIn = false;
      state.user = null;
      state.usertype = null;
      state.libraryCovalentsAccess = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.usertype = null;
      state.libraryCovalentsAccess = null;
      state.error = null;
    },
    setCompoundPageVersion: (state, action: PayloadAction<number>) => {
      state.compoundPageVersion = action.payload;
    },
  },
});

export const { loginSuccess, loginFailure, logout, setCompoundPageVersion } = loginSlice.actions;
export default loginSlice.reducer;
