// loadModal.js
import { createToast } from '../../controls/toast'
import { createModal } from './modalHandler'

// Centralized approach for managing modal actions, templates, and event listeners
const modalActions = {
    Login: {
        title: '',
        getTemplate: () => import('../../../templates/auth/loginFormTemplate'),
        reinitialize: () => import('../../auth/signIn.js'),
    },
    Register: {
        title: '',
        getTemplate: () => import('../../../templates/auth/registerFormTemplate'),
        reinitialize: () => import('../../auth/signUp.js'),
    },
    ForgotPassword: {
        title: '',
        getTemplate: () => import('../../../templates/auth/forgotPasswordFormTemplate'),
        reinitialize: () => import('../../auth/forgotPassword.js'),
    },
    ForgotPasswordSuccess: {
        title: '',
        getTemplate: () => import('../../../templates/auth/forgotPasswordSuccessTemplate'),
    },
    QuestionsAfterRegistration: {
        title: '',
        getTemplate: () => import('../../../templates/auth/questionaireFormTemplate'),
        reinitialize: () => import('../../auth/questions.js'), // This assumes you have a reinitializer for the questionnaire
    },
    Activation: {
        title: '',
        getTemplate: () => import('../../../templates/auth/activationFormTemplate'),
        reinitialize: () => Promise.resolve({ reinitialize: () => {} }),
    },
    ShareCart: {
        title: '',
        size: 'lg',
        getTemplate: () => import('../../../templates/shoppingcart/shareCartFormTemplate.js'),
        reinitialize: () => import('../../shoppingcart/shareCart'),
    },
    ShareCartSuccess: {
        size: 'lg',
        title: '',
        getTemplate: () => import('../../../templates/shoppingcart/shareCartSuccessFormTemplate.js'),
        reinitialize: () => Promise.resolve({ reinitialize: () => {} }),
    },
    ChangeCountry: {
        title: '',
        getTemplate: () => import('../../../templates/changecountry/changeCountryFormTemplate'),
        reinitialize: () => import('../../changecountry/changeCountry.js'),
    },    
}

export async function loadModal(actionKey, onShown = null, notClosable = false) {
    const action = modalActions[actionKey]
    const title = modalActions[actionKey].title || ''
    const size = modalActions[actionKey].size || ''
    if (!action) {
        console.error(`Action ${actionKey} not defined.`)
        return
    }

    // Determine the modal options based on the `notClosable` parameter
    const modalOptions = {
        backdrop: notClosable ? 'static' : true, // 'static' means the modal does not close when clicking outside it
        keyboard: !notClosable, // false if the modal should not close when pressing ESC
        size: size,
    }

    try {
        const { default: template } = await action.getTemplate()
        const bodyContent = template() // Assuming the imported module exports a function returning an HTML string
        createModal(actionKey.toLowerCase(), title, bodyContent, onShown, modalOptions)

        // After the modal content is loaded and the modal is displayed,
        // check if reinitialize function is defined and then import the module for reinitializing event listeners and execute it
        if (action.reinitialize) {
            const { reinitialize } = await action.reinitialize()
            if (typeof reinitialize === 'function') {
                reinitialize()
            }
        }
    } catch (error) {
        console.error(`The modal cannot be loaded due to error: ${actionKey}`, error)
        createToast({ message: 'Sorry, but the error occurred. Please try later.', toastType: 'bg-danger', icon: 'ph-warning-circle' })
    }
}
